
<div *ngIf="loading" class="min-vh-100 d-flex justify-content-center pt-5">
    <div class="form-container">
        <form>
            <div class="white-card">
                <div class="broken-wrap">
                    <p class="text-center">
                        Loading
                    </p>
                </div>
            </div>
        </form>
    </div>
</div>


<div ngForm #requestPINForm="ngForm" *ngIf="!loading && !sessionStarted && this.vm.ClaimsAuthenticationServiceActive" class="min-vh-100 d-flex justify-content-center pt-5">
    <div class="form-container">
        <h1 class="text-center main-heading">You will receive a PIN shortly</h1>
        <h2 class="text-center sub-heading">How would you like to receive your PIN?</h2>


        <div class="white-card">
            <div class="pin-wrap">
                <div *ngIf="!IsNullOrEmptyString(this.vm.Email)" class="option-container">
                    <input required type="radio" name="DeliveryMethod" [(ngModel)]="vm.DeliveryMethod" #deliveryMethod="ngModel" value="Email" id="emailOption" class="option-input" checked>
                    <label class="option-label" for="emailOption">
                        {{this.vm.Email}}
                    </label>
                </div>

                <div *ngIf="!IsNullOrEmptyString(this.vm.CellPhoneNum)" class="option-container">
                    <input required type="radio" name="DeliveryMethod" [(ngModel)]="vm.DeliveryMethod" #deliveryMethod="ngModel" value="SMS" id="phoneOption" class="option-input">
                    <label class="option-label" for="phoneOption">
                        {{this.vm.CellPhoneNum}}
                    </label>
                </div>

                <button type="submit" class="btn btn-success w-100 request-btn" (click)="submitPINRequest(requestPINForm)">
                    Request PIN
                </button>

            </div>
        </div>

        <p class="text-center small-text">
            <strong>If you do not recognize the email address or phone number, please contact your adjuster.</strong>
        </p>

    </div>
</div>


<div ngForm #VerifyPINForm="ngForm" *ngIf="(!loading && sessionStarted && this.vm.ClaimsAuthenticationServiceActive && !this.vm.MaxFailureAttempts)" class="min-vh-100 d-flex justify-content-center pt-5">
    <div class="form-container">
        <h2 class="text-center sub-heading">Enter the PIN to securely access your MC360 Dashboard</h2>

        <div class="white-card">
            <div class="login-wrap">
                <label for="pin" class="form-label">Enter the 6 digit PIN below</label>
                <input Validate name="pin" [(ngModel)]="vm.PIN" type="password"
                       class="form-control broken-wrap" OnlyNumbers
                       id="pin"
                       maxlength="6"
                       placeholder="••••••"
                       #inputPin="ngModel"
                       [class.ng-invalid]="inputPin.invalid && !inputPin.pristine"
                       required>

                <div *ngIf="this.verifyStarted && !this.vm.Verified && !IsNullOrEmptyString(this.vm.Message)" class="broken-wrap invalid-feedback">{{this.vm.Message}}</div>
                <div *ngIf="this.verifyStarted && !this.vm.Verified && this.displayMessage">You should receive a new PIN shortly. Please enter it within 15 minutes.</div>
                <div *ngIf="this.displayEnterPINMessage" class="broken-wrap invalid-feedback">Please enter the PIN</div>
                <button type="submit" class="btn btn-success w-100 mt-3 request-btn" (click)="submitVerifyPIN(VerifyPINForm)">
                    Submit
                </button>

                <button type="submit" class="btn btn-success w-100 mt-3 btn-light white-outline-btn" (click)="resetPIN()">
                    Resend PIN
                </button>
            </div>
        </div>


    </div>
</div>

<div *ngIf="this.vm.MaxFailureAttempts && this.vm.ClaimsAuthenticationServiceActive" class="min-vh-100 d-flex justify-content-center pt-5">
    <div class="form-container">
        <form>
            <div class="white-card">
                <div class="broken-wrap">
                    <img class="a-icon" src="assets/img/exclaim-icon.png">
                    <p class="text-center">
                        Whoops! It looks like you are having trouble. Please contact your Claims adjuster for assistance.
                    </p>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="!(this.vm.ClaimsAuthenticationServiceActive)" class="min-vh-100 d-flex justify-content-center pt-5">
    <div class="form-container">
        <form>
            <div class="white-card">
                <div class="broken-wrap">
                    <img class="a-icon" src="assets/img/exclaim-icon.png">
                    <p class="text-center">
                        We are sorry, the service is currently unavailable. Please contact your adjuster for assistance.
                    </p>
                </div>
            </div>
        </form>
    </div>
</div>
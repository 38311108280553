import { OnInit, AfterViewInit, Component } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";

@Component({
    template: ''
})
export abstract class BaseComponent implements OnInit, AfterViewInit {
    public abstract ngOnInit(): void;
    public loadedInfo: string = 'default';

    constructor() {

    }

    protected validate(form: NgForm): boolean {
        Object.keys(form.controls).forEach(key => {
            let value = form.controls[key].value;
            if (typeof value === 'string' && !this.IsNullOrEmptyString(value)) {
                if (value.trim().length === 0) {
                    form.controls[key].setValue("");
                    form.controls[key].markAsTouched();
                }
            }
        })
        if (form && form.valid) {
            return true;
        } else {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsDirty();
                form.controls[key].markAsTouched();
            });
            this.findFirstInvalidElement();
            return false;
        }
    }

    public findFirstInvalidElement() {
        const invalidElement: HTMLElement = document.querySelector('div[ngForm] .ng-invalid');
        this.setInvalid(invalidElement);
    }

    // this is only for specific circumstances where the form
    // will be invalid but isn't yet.
    // Example: AddressValidation popup sets the Zip to blank and we exit out
    public setInvalid(invalidElement: HTMLElement): void {
        if (invalidElement) { // ensure we have an object
            invalidElement.scrollIntoView({ behavior: 'smooth' });
            invalidElement.focus();
        }
    }

    public IsNullOrEmptyString(value: string): boolean {
        if (value === null || value === undefined || value === '') {
            return true;
        }

        return false;
    }

    public scrollToTopOfPage() {
        window.scroll({
            top: 0, left: 0, behavior: 'smooth'
        })
    }

    public haveDataFor(value: string): boolean {
        if (value !== null && value !== "" && value !== undefined) {
            return true;
        }

        return false;
    }

    // this runs after the components view has been initialized
    public ngAfterViewInit(): void {
        this.scrollToTopOfPage();
    }

    public parseBoolean(value?: string | number | boolean | null) {
    value = value?.toString().toLowerCase();
    return value === 'true' || value === '1';
    }
}
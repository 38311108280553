import { BaseModel } from './BaseModel';
import { SessionModel } from './SessionModel';
import { ContactModel } from './ContactModel';

export class BridgeModel extends BaseModel {

    public DashboardSessionGuid: string = null;
    public AuthenticationSessionGuid: string = null; 
    public Session: SessionModel = null;
    public LossParticipantId: number = 0;
    public Email: string = null; 
    public CellPhoneNum: string = null; 
    public DeliveryMethod: string = null;
    public Action: string = null;
    public ClaimsAuthenticationServiceActive: boolean = false;
    public Verified: boolean = false;
    public Message: string = null;
    public MaxFailureAttempts: boolean = false;
    public PIN: string = null;
    public ContactID: string = null;
    public ClaimID: number = 0;
    public ClaimNumber: string = null;

}

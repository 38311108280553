import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

    // MC360 Bridge PIN Authentication constants
    // Action 
    public readonly RequestPIN: string = "RequestPIN";
    public readonly VerifyPIN: string = "VerifyPIN";
    public readonly ResetPIN: string = "ResetPIN";
    

    // DeliveryMethod 
    public readonly Email: string = "Email";
    public readonly SMS: string = "SMS";
    
    // AccidentRole
    public readonly AccidentRolePedestrian: string = "PED";

    //PhoneType
    public readonly PhoneTypeWork: string = "WORK";

    // ClaimTypeCode
    public readonly ClaimTypeCodeMechanicalBreakdown: string = "MECHBREAK";
    public readonly ClaimTypeCodeEarthquake: string = "WTHREQ";
    public readonly ClaimTypeCodeHail: string = "WTHRHL";
    public readonly ClaimTypeCodeFloodRisingWater: string = "WTHRFLDWTR";
    public readonly ClaimTypeCodeWindTornado: string = "WTHRWNDTN";
    public readonly ClaimTypeCodeSnowIce: string = "WTHRSNICE";
    public readonly ClaimTypeCodeLightning: string = "WTHRLGHT";
    public readonly ClaimTypeCodeTheft: string = "THEFT";
    public readonly ClaimTypeCodeFire: string = "FIRE";
    public readonly ClaimTypeCodeVandalism: string = "VANDAL";
    public readonly ClaimTypeCodeSingleVehicleLoss: string = "SVL";
    public readonly ClaimTypeCodeTireBlowout: string = "SVLTIRE";
    public readonly ClaimTypeCodeRoadHazard: string = "SVLRDHZ";
    public readonly ClaimTypeCodeRoadHazardNF: string = "SVLRDHZNF";
    public readonly ClaimTypeCodeFallingObject: string = "SVLFLLNG";
    public readonly ClaimTypeCodeHitAndRun: string = "SVLHITRN";
    public readonly ClaimTypeCodeAnimalCollision: string = "SVLANIMAL";
    public readonly ClaimTypeCodeFixedProperty: string = "FIXEDPROP";
    public readonly ClaimTypeCodeRearEndAccidentAF: string = "MVLRENDAF";
    public readonly ClaimTypeCodeRearEndAccidentNF: string = "MVLRENDNAF";
    public readonly ClaimTypeCodeParkingLotAccidentAF: string = "MVLPLAF";
    public readonly ClaimTypeCodeParkingLotAccidentNF: string = "MVLPLNAF";
    public readonly ClaimTypeCodeBackingAccidentAF: string = "MVLBAF";
    public readonly ClaimTypeCodeBackingAccidentNF: string = "MVLBNAF";
    public readonly ClaimTypeCodeIntersectionAccident: string = "MVLINTAF";
    public readonly ClaimTypeCodeHeadOnCollision: string = "MVLHDON";
    public readonly ClaimTypeCodePassingMergingAccidentAF: string = "MVLPSSAF";
    public readonly ClaimTypeCodePassingMergingAccidentNF: string = "MVLPSSNAF";
    public readonly ClaimTypeCodeSideswipeAccidentAF: string = "MVLSSAF";
    public readonly ClaimTypeCodeSideswipeAccidentNF: string = "MVLSSNAF";
    public readonly ClaimTypeCodeInMotion: string = "MVLMTN";
    public readonly ClaimTypeCodeNotInMotion: string = "MVLNMTN";
    public readonly ClaimTypeCodeMiscNF: string = "SVLMISCNF";
    public readonly ClaimTypeCodeMultiVehicleHitAndRun: string = "MVLHITRN";
    public readonly ClaimTypeCodePedAF: string = "PEDDRVR";
    public readonly ClaimTypeCodePedNF: string = "PEDHIT";
    public readonly ClaimTypeCodeTOWLockOut: string = "TOWLOCKOUT";
    public readonly ClaimTypeCodeTOWJumpStart: string = "TOWJMPSTRT";
    public readonly ClaimTypeCodeTOWFlatTire: string = "TOWFLATTRE";
    public readonly ClaimTypeCodeTOWGasDelivery: string = "TOWGASDLV";
    public readonly ClaimTypeCodeTOWWinch: string = "TOWWINCH";
    public readonly ClaimTypeCodeTOWIllegallyParked: string = "TOWILLPARK";
    public readonly ClaimTypeCodeTOWImpounded: string = "TOWIMPOUND";
    public readonly ClaimTypeCodeTOWOther: string = "TOWOTHER";
    public readonly ClaimTypeCodeAwning: string = "AWNING";
    public readonly ClaimTypeCodeGlass: string = "GLASS";
    public readonly ClaimTypeCodeRVAnimalsNF: string = "MISCNFANM";
    public readonly ClaimTypeCodeRVWater: string = "MISCWATER";
    public readonly ClaimTypeCodeRVElectricalNF: string = "MISCNFELEC";
    public readonly ClaimTypeCodeRVOtherNF: string = "MISCNFOTH"

    public readonly ClaimTypeSingleVehicle: string[] = [
        this.ClaimTypeCodeSingleVehicleLoss,
        this.ClaimTypeCodeTireBlowout,
        this.ClaimTypeCodeRoadHazard,
        this.ClaimTypeCodeRoadHazardNF,
        this.ClaimTypeCodeFallingObject,
        this.ClaimTypeCodeHitAndRun,
        this.ClaimTypeCodeAnimalCollision,
        this.ClaimTypeCodeFixedProperty,
        this.ClaimTypeCodeMiscNF
    ];


    public readonly ClaimTypeMultiVehicle: string[] = [
        this.ClaimTypeCodeRearEndAccidentAF,
        this.ClaimTypeCodeRearEndAccidentNF,
        this.ClaimTypeCodeParkingLotAccidentAF,
        this.ClaimTypeCodeParkingLotAccidentNF,
        this.ClaimTypeCodeBackingAccidentAF,
        this.ClaimTypeCodeBackingAccidentNF,
        this.ClaimTypeCodeIntersectionAccident,
        this.ClaimTypeCodeHeadOnCollision,
        this.ClaimTypeCodePassingMergingAccidentAF,
        this.ClaimTypeCodePassingMergingAccidentNF,
        this.ClaimTypeCodeSideswipeAccidentAF,
        this.ClaimTypeCodeSideswipeAccidentNF,
        this.ClaimTypeCodeInMotion,
        this.ClaimTypeCodeNotInMotion,
        this.ClaimTypeCodeMultiVehicleHitAndRun
    ];


    public readonly ClaimTypePedestrian: string[] = [
        this.ClaimTypeCodePedAF,
        this.ClaimTypeCodePedNF
    ];

    public readonly ClaimTypeMechanicalBreakdown: string[] = [
        this.ClaimTypeCodeMechanicalBreakdown,
        this.ClaimTypeCodeTOWLockOut,
        this.ClaimTypeCodeTOWJumpStart,
        this.ClaimTypeCodeTOWFlatTire,
        this.ClaimTypeCodeTOWGasDelivery,
        this.ClaimTypeCodeTOWWinch,
        this.ClaimTypeCodeTOWIllegallyParked,
        this.ClaimTypeCodeTOWImpounded,
        this.ClaimTypeCodeTOWOther
    ];
    public readonly ClaimTypeWeather: string[] = [
        this.ClaimTypeCodeEarthquake,
        this.ClaimTypeCodeHail,
        this.ClaimTypeCodeFloodRisingWater,
        this.ClaimTypeCodeWindTornado,
        this.ClaimTypeCodeSnowIce,
        this.ClaimTypeCodeLightning
    ];


    public readonly ClaimTypeRVMisc: string[] = [
        this.ClaimTypeCodeRVAnimalsNF,
        this.ClaimTypeCodeRVElectricalNF,
        this.ClaimTypeCodeRVOtherNF,
        this.ClaimTypeCodeRVWater
    ]

    public readonly ClaimTypeGlass: string[] = [
        this.ClaimTypeCodeGlass
    ]

    public readonly ClaimTypeTheftVandalismFire: string[] = [
        this.ClaimTypeCodeTheft,
        this.ClaimTypeCodeFire,
        this.ClaimTypeCodeVandalism
    ]


    public readonly SingleCar: string = "singlecar";
    public readonly Windshield: string = "windshield";
    public readonly Mechanical: string = "mechanical";
    public readonly Theft: string = "theft";
    public readonly Vandalism: string = "vandalism";
    public readonly WeatherEvent: string = "weatherEvent";
    public readonly Fire: string = "fire";
    public readonly Pedestrian: string = "pedestrian";
    public readonly VehicleAccident: string = "vehicleAccident";
    public readonly MultipleVehicleAccident: string = "multipleVehicleAccident";
    public readonly Earthquake: string = "earthquake";
    public readonly Hail: string = "hail";
    public readonly Flood: string = "flood";
    public readonly WindTornado: string = "windTornado";
    public readonly Snow: string = "snow";
    public readonly Lightning: string = "lightning";
    public readonly SingleVehicleOther: string = "singleVehicleOther";
    public readonly TireBlowout: string = "tireBlowout";
    public readonly RoadHazard: string = "roadHazard";
    public readonly RoadHazardNF: string = "roadHazardNAF";
    public readonly FallingObject: string = "fallingObject";
    public readonly HitAndRun: string = "hitAndRun";
    public readonly FixedProperty: string = "fixedProperty";
    public readonly AnimalCollision: string = "animalCollision";
    public readonly RearEndedAF: string = "rearEndedAF";
    public readonly RearEndedNAF: string = "rearEndedNAF";
    public readonly ParkingLotAF: string = "parkingLotAF";
    public readonly ParkingLotNAF: string = "parkingLotNAF";
    public readonly BackingAF: string = "backingAF";
    public readonly BackingNAF: string = "backingNAF";
    public readonly Intersection: string = "intersection";
    public readonly HeadOn: string = "headOn";
    public readonly PassingAF: string = "passingAF";
    public readonly PassingNAF: string = "passingNAF";
    public readonly SideSwipeAF: string = "sideSwipeAF";
    public readonly SideSwipeNAF: string = "sideSwipeNAF";
    public readonly InMotion: string = "inMotion";
    public readonly NotInMotion: string = "notInMotion";
    public readonly HitPedestrian: string = "hitPedestrian";
    public readonly WasHit: string = "wasHit";
    public readonly Collision: string = "collision";
    public readonly TheftVandalism: string = "theftVandalism";

    public readonly RearEndedCategory: string = "rearEndedCategory";
    public readonly ParkingLotCategory: string = "parkingLotCategory";
    public readonly BackingCategory: string = "backingCategory";
    public readonly PassingMergingCategory: string = "passingMergingCategory";
    public readonly SideswipeCategory: string = "sideswipeCategory";
    public readonly OtherCategory: string = "otherCategory";
    public readonly MultiVehicleSubType: string = "multiVehicleSubType";
    public readonly MultiVehicleHitAndRun: string = "multiVehicleHitAndRun";

    public readonly MechanicalBreakdown: string = "mechanicalBreakdown";
    public readonly LockOut: string = "lockOut";
    public readonly JumpStart: string = "jumpStart";
    public readonly FlatTire: string = "flatTire";
    public readonly GasDelivery: string = "gasDelivery";
    public readonly Winch: string = "winch";
    public readonly IllegallyParkedTow: string = "illegallyParkedTow";
    public readonly Impounded: string = "impounded";
    public readonly Other: string = "other";

    public readonly Awning: string = "awning";
    public readonly Glass: string = "glass";
    public readonly MiscRVDamages: string = "miscRV";
    public readonly MiscRVAnimal: string = "miscRVAnimalDamage";
    public readonly MiscRVWater: string = "miscRVWaterDamage";
    public readonly MiscRVElectrical: string = "miscRVElectricalDamage";
    public readonly MiscRVOther: string = "miscRVOtherDamage";

    // Claim Types
    public readonly FNOLAuto = "FNOL";
    public readonly FNOLHome = "FNOLHome";
    public readonly FNOLThirdParty = "FNOLThirdParty";
    public readonly FNOLWatercraft = "FNOLWater";
    public readonly FNOLRV = "FNOLRV";
    public readonly FNOLMotorcycle = "FNOLMoto";
    public readonly FNOLCommercialAuto = "FNOLCA";
    public readonly FNOLFarm = "FNOLFarm";
    public readonly FNOLLife = "FNOLLife";
    public readonly FNOLOther = "FNOLOther";

    public readonly FNOLThirdPartyAuto = "FNOLThirdPartyAuto";

    public readonly EMS: string = "ems";
    public readonly Police: string = "police";
    public readonly Firefighter: string = "firefighter";

    public readonly Insured: string = "INSURED";
    public readonly Claimant: string = "CLAIMANT";

    public readonly Owner: string = "Owner";
    public readonly Driver: string = "Driver";
    public readonly Passenger: string = "Passenger";
    public readonly ThirdPartyOwner: string = "thirdPartyOwner";
    public readonly ThirdPartyDriver: string = "thirdPartyDriver";
    public readonly ThirdPartyPassenger: string = "thirdPartyPassenger";


    public readonly ParticipantPhoneNumber: string = "participantPhoneNumber";
    public readonly ParticipantPhoneType: string = "participantPhoneType";

    public readonly FalseStringValue: string = "false";
    public readonly TrueStringValue: string = "true";

    public readonly YesStringValue: string = "yes";
    public readonly NoStringValue: string = "no";
    public readonly UnknownStringValue: string = "unknown";

    public readonly Keep: string = "Keep";
    public readonly Change: string = "Change";

    public readonly EmailTypePER: string = "PER";
    public readonly PhoneTypeHome: string = "HOME";
    public readonly PhoneTypeFax: string = "FAX";

    public readonly InsuranceCarrierOther: string = "Other";

    
    public readonly HideUnknownOption: string = "HideUnknownOption";

    public readonly Submit: string = "Submit";

    public readonly EmptyString: string = "";

    public readonly ClaimsDepartmentString = "Claims Department";
    public readonly ClaimsDepartmentInitialString = "CD";

    // ClaimType Help Text
    public readonly WeatherEventHelpText: string = "Example: Damage to vehicle caused by earthquake, hail, lightning, etc.";
    public readonly VandalismHelpText: string = "Example: Vehicle broken into, stolen stereo, keyed, etc.";
    public readonly TheftHelpText: string = "Example: The vehicle was stolen.";
    public readonly FireHelpText: string = "Example: Engine or interior fire of the vehicle.";
    public readonly VehicleHelpText: string = " Example:  An accident involving one or more vehicles.";
    public readonly SingleVehicleHelpText: string = " Example:  An accident involving only one vehicle, a hit and run, or a vehicle hitting a property (mailbox, pole).";
    public readonly MultipleVehicleHelpText: string = "Example: Two or more vehicles involved in an accident.";
    public readonly PedestrianHelpText: string = "Example: I was struck as a pedestrian (walking, bicycling) or I struck a pedestrian.";
    public readonly MechanicalHelpText: string = "Reimbursement for Towing or other Roadside Services not related to an auto accident.";
    public readonly GlassOnlyHelpText: string = "Example: Any event which causes glass only damage to your vehicle.";
    public readonly CollisionHelpText: string = "Example: Accidents involving one or more vehicle or with a pedestrian";
    public readonly TheftVandalismHelpText: string = "Vehicle was stolen or broken into";
    public readonly MiscRVHelpText: string = "Damages caused by animals, Electrical, Burst Pipes, etc.";

    public readonly OtherStructuresHelpText: string = "Items like a Garage, Fence, Gazebo, Mailbox, etc.";
    public readonly PersonalPropertyHelpText: string = "Computer, TV, Jewelry, Etc. that is Stolen, Missing, or Damaged";
    public readonly EquipmentBreakdownHelpText: string = "Failure of Equipment in the Home like Heating/Cooling, Refrigerator, Etc.";

    public readonly PersonalThirdPartyAutoHelpText: string = "Claim involves a National General Personal Auto";
    public readonly HomeHelpText: string = "Claim involves a National General Home/Dwelling";
    public readonly RVHelpText: string = "Claim involves a National General RV/Motorhome/Fifth Wheel";
    public readonly MotorcycleHelpText: string = "Claim involves a National General Motorcycle";
    public readonly CommercialVehicleHelpText: string = "Claim involves a National General Commercial Vehicle";
    public readonly OtherHelpText: string = "All other claims";

    // Vehicle Location value
    public readonly BodyShopLocationValue: string = "BODYSHOP";
    public readonly ImpoundYardLocationValue: string = "IMPYARD";
    public readonly TowYardLocationValue: string = "TOWYARD";
    public readonly WorkLocationValue: string = "WORK";
    public readonly ResidenceLocationValue: string = "RESIDENCE";
    public readonly ResidenceLocationDisplayCode: string = "OWNDRVRES";

    // VehicleType
    public readonly RecreationVehicle: string = "RECVEH";

    // Placeholder text
    public readonly MechanicalPlaceholderText: string = "I had a flat tire, I ran out of gas, etc.";
    public readonly EarthquakePlaceholderText: string = "Debris fell on my vehicle during an earthquake, etc.";
    public readonly HailPlaceholderText: string = "The hood and roof of my vehicle were damaged in a hail storm, etc.";
    public readonly FloodPlaceholderText: string = "My vehicle was flooded due to rising waters, etc.";
    public readonly WindPlaceholderText: string = "Wind blew debris into my vehicle, etc.";
    public readonly SnowPlaceholderText: string = "I lost control of my vehicle during icy conditions on the road, etc.";
    public readonly LightningPlaceholderText: string = "My vehicle was struck by lightning, etc.";
    public readonly TheftPlaceholderText: string = "Someone stole my vehicle from my residence, etc.";
    public readonly FirePlaceholderText: string = "My vehicle engine caught on fire while driving, etc.";
    public readonly VandalismPlaceholderText: string = "Someone broke into my vehicle and stole my stereo, etc.";
    public readonly TireBlowOutPlaceholderText: string = "My right front tire blew, etc.";
    public readonly RoadHazardPlaceholderText: string = "I hit a pothole while driving, etc.";
    public readonly HitAndRunPlaceholderText: string = "Another vehicle struck my vehicle in a parking lot and fled the scene, etc.";
    public readonly FallingObjectPlaceholderText: string = "A tree branch fell on my parked vehicle, etc.";
    public readonly AnimalPlaceholderText: string = "I struck a deer that ran out in front of my vehicle, etc.";
    public readonly FixedPropertyPlaceholderText: string = "I lost control of my vehicle going around a curve and hit a mailbox, etc.";
    public readonly OtherSVLPlaceholderText: string = "My vehicle rolled down my driveway and hit the curb, etc.";
    public readonly RearEndFaultPlaceholderText: string = "I rear-ended another vehicle at a stop light, etc.";
    public readonly RearEndNotFaultPlaceholderText: string = "I was rear-ended at a stop light, etc.";
    public readonly IntersectionPlaceholderText: string = "A vehicle made a left turn in front of me while I was proceeding through the intersection, etc.";
    public readonly ParkingLotNotFaultPlaceholderText: string = "Another vehicle backed out of a parking spot and struck my vehicle, etc.";
    public readonly ParkingLotFaultPlaceholderText: string = "I backed out of my parking spot and struck another vehicle, etc.";
    public readonly BackingNotFaultPlaceholderText: string = "Someone backed into my vehicle.";
    public readonly BackingFaultPlaceholderText: string = "I backed into another vehicle.";
    public readonly HeadOnCollisionPlaceholderText: string = "An oncoming vehicle swerved into my lane of travel and caused a head on collision, etc.";
    public readonly PassingMergingFaultPlaceholderText: string = "I was merging from an on ramp and struck another vehicle, etc.";
    public readonly PassingMergingNotFaultPlaceholderText: string = "A vehicle attempted to merge from the on ramp and struck my vehicle, etc.";
    public readonly SideswipeFaultPlaceholderText: string = "I sideswiped the vehicle in the left lane, etc.";
    public readonly SideswipeNotFaultPlaceholderText: string = "The vehicle next to me swerved and sideswiped my vehicle, etc.";
    public readonly InMotionPlaceholderText: string = "While driving on the interstate, a vehicle crossed the median and struck my vehicle.";
    public readonly NotInMotionPlaceholdertext: string = "While sitting stationary at a red light, a vehicle crossed the median and struck my vehicle.";
    public readonly PedestrianFaultPlaceholderText: string = "While driving my vehicle, I struck a person who was crossing the street, etc.";
    public readonly PedestrianNotFaultPlaceholderText: string = "I was walking in the cross walk when a vehicle crossing the intersection hit me, etc.";
    public readonly LossDescriptionPlaceholderText: string = "My vehicle engine caught on fire while driving, etc.";
    public readonly DamagesNotRelatedToWeatherOrFireText: string = "My vehicle was damaged by burst pipe, power surge etc.";

    public readonly SingleVehicleRVPlaceholderText: string = "Another vehicle struck my RV in a parking lot and fled the scene, etc.";
    public readonly MultiVehicleRVPlaceholderText: string = "Another vehicle struck my RV in a parking lot and fled the scene, etc.";
    public readonly WeatherEventRVText: string = "My vehicle was damaged by Hail, Flood, Snow etc.";

    public readonly DamageDescriptionPlaceholderText: string = "Please enter a description of the damages on the vehicle.";
    public readonly TheftPriorDamageDescriptionPlaceholderText: string = "Please enter a description of damages prior to the theft.";
    public readonly PropertyDamageDescriptionPlaceholderText: string = "Dented, scraped, leaning, etc.";
    public readonly EmailPlaceholderText: string = "Email";
    public readonly FirstNamePlaceholderText: string = "First Name";
    public readonly LastNamePlaceholderText: string = "Last Name";
    public readonly MiddleNamePlaceholderText: string = "MI";
    public readonly AddressPlaceholderText: string = "Address";
    public readonly CityPlaceholderText: string = "City";
    public readonly DateFormatPlaceholderText: string = "mm/dd/yyyy";
    public readonly MakePlaceholderText: string = "(i.e. Toyota)";
    public readonly ModelPlaceholderText: string = "(i.e. Camry)";
    public readonly PhoneFormatPlaceholderText: string = "xxx-xxx-xxxx";
    public readonly PlateNumberFormatPlaceholderText: string = "xxxxxxx";
    public readonly YearPlaceholderText: string = "(i.e 2010)";
    public readonly ZipPlaceholderText: string = "Zip";
    public readonly PropertyDescriptionPlaceholderText: string = "Fence, mailbox, tree, pole, house, etc.";

    public readonly LossHappenedPlaceholderText: string = "Example: Tree fell on my roof...";
    public readonly SpecificDamagedCausedPlaceholderText: string = "Example: Roof and shingles on the home were damaged...";

    public readonly ClaimsWebBrandSessionKey: string = "claimsWebBrand";
    public readonly SessionSettingKey: string = "sessionSettings";
    public readonly RecordSessionKey: string = "recordSessionSettings";
    public readonly WorkflowTypeKey: string = "workflowType";

    public readonly HasOccupantsInVehicleKey: string = "HasOccupantsInVehicle";
    public readonly AwningSelectedKey: string = "awningSelected"
    //Dashboard Claim Status
    public readonly ClaimStatusInitial: string = "LOSSREPORT";
    public readonly ClaimStatusOpen: string = "OPEN";
    public readonly ClaimStatusClosed: string = "CLOSED";

    // Policy Sub Type
    public readonly Preferred: string = "Preferred";
    public readonly Premier: string = "Premier";
    public readonly Farmers: string = "Farmers";
    public readonly Imperial: string = "Imperial";
    public readonly PEIC: string = "PEIC";
    public readonly NFUFarmAuto: string = "NFU Farm Auto";
    public readonly StandardMutual: string = "Standard Mutual";
    public readonly Century: string = "Century";

    // Emergency user call-In contact number
    public readonly PreferredContactNumber: string = "888-325-1190";
    public readonly PremierContactNumber: string = "844-287-2236";
    public readonly FarmersContactNumber: string = "866-638-5677";
    public readonly ImperialContactNumber: string = "800-468-3466";
    public readonly PEICContactNumber: string = "800-499-3612";
    public readonly OtherContactNumber: string = "800-468-3466";
    public readonly EncompassContactNumber: string = "800-588-7400";
    public readonly EsuranceContactNumber: string = "800-378-7262";
    public readonly SafeAutoContactNumber: string = "800-723-3288";
    public readonly Custom360ContactNumber: string = "888-325-1190";

    // Home Claim Image Categories
    public readonly HomeEquipment: string = "Equipment";
    public readonly HomeOtherStructure: string = "OtherStructure";
    public readonly HomePersonalProperty: string = "PersonalProperty";

    // Home Type of Loss
    public readonly Equipment: string = "Equipment";
    public readonly OtherStructure: string = "Other Structure";
    public readonly PersonalProperty: string = "Personal Property";
    public readonly Dwelling: string = "Dwelling";

    // Brands
    public readonly MC360: string = "myclaims360";
    public readonly NationalGeneral: string = "nationalgeneral";
    public readonly Encompas: string = "encompassinsurance"
    public readonly Esurance: string = "esurance"
    public readonly SafeAuto: string = "safeauto"
    public readonly FarmersUnion: string = "farmersunioninsurance"
    public readonly CustomerValidation: string = 'customerValidation'


    // Mobile Device Info
    public readonly MobileWidthThresholdMax: number = 768;

    //Unofficial Coverage Assigment Type
    public readonly UnofficialCoverageMaterialDamageFirstParty: string = "Material Damage - First Party";
    public readonly UnofficialCoverageMaterialDamageThirdParty: string = "Material Damage - Third Party";
    public readonly UnofficialCoverageMedicalPayments: string = "Medical Payments";
    public readonly UnofficialCoveragePersonalInjuryProtection: string = "Personal Injury Protection";
    public readonly UnofficialCoverageBodilyInjury: string = "Bodily Injury";

    // Claim Type Adjuster Role
    public readonly VehicleAdjuster: string = "Vehicle Adjuster";
    public readonly InjuryAdjuster: string = "Injury Adjuster";
    public readonly PropertyAdjuster: string = "Property Adjuster";

    //DashboardHeader GUID empty format
    public readonly EmptyGUIDFormat: string = "00000000-0000-0000-0000-000000000000";


    public get HomeClaimTypeNames(): typeof HomeClaimTypeNames {
        return HomeClaimTypeNames;
    }

    public get RoadsidePhoneNumbers(): typeof RoadsidePhoneNumbers {
        return RoadsidePhoneNumbers;
    }

    public get ClaimsEmail(): typeof ClaimsEmail {
        return ClaimsEmail;
    }

    public get ClaimsPhone(): typeof ClaimsPhone {
        return ClaimsPhone;
    }

    public get PolicyMCOName(): typeof PolicyMCOName {
        return PolicyMCOName;
    }

    public get SessionKeys(): typeof SessionKeys {
        return SessionKeys;
    }

    public get GlassPhoneNumbers(): typeof GlassPhoneNumbers {
        return GlassPhoneNumbers;
    }
}

// These fields are used in the UI
enum HomeClaimTypeNames {

    // Equipment Breakdown
    EquipmentBreakdown = "Equipment Breakdown",

    // Property
    PropertyFire = "Fire",
    PropertyTheft = "Theft or Stolen Items",
    PropertyMissingLost = "Lost or Missing Items",

    // Weather Related: Yes
    Earthquake = "Earthquake",
    LightningNoFire = "Lightning - No Fire",
    SnowIce = "Ice Dam/Snow/Weight of Ice",
    WindTornado = "Wind/Tornado",
    FireWildfire = "Fire",
    Hail = "Hail",
    WaterDamage = "Water Damage",

    // Weather Related: No 
    Explosion = "Explosion",
    Mold = "Mold",
    Smoke = "Smoke",
    NoWeather_WaterDamage = "Water Damage",
    NoWeather_Fire = "Fire",
    SinkHole = "Sinkhole/Ground Collapse",
    Vandalism = "Vandalism or Destruction of Property",
    CrashIntoHome = "Vehicle Crashing into Home, Garage, Etc.",
    Riot = "Riot"
}

enum RoadsidePhoneNumbers {
    Adirondack = "800-668-1398",
    NJSkylands = "866-247-4674",
    Encompass = "800-588-7400",
    Preferred = "800-547-4663",
    Premier = "800-547-4663",
    PEIC = "800-547-4663",
    Esurance = "877-508-4131",
    SafeAuto = "800-308-8959",
    Default = "800-468-3466",
    Custom360 = "800-547-4663"
}

enum PolicyMCOName {
    NatGenPrimerIns = "National General Premier Insurance Company"
}

enum SessionKeys {
    ClaimsWebBrand = "claimsWebBrand"
}

enum GlassPhoneNumbers {
    Default = "855-326-1882",
    Encompass = "800-588-7400",
    Custom360 = "800-588-7400-option 2"
}

enum ClaimsEmail {
    Encompass = "encompassclaimsmail@encompassins.com",
    Esurance = "claims@esurance.com",
    Default = "Claims@ngic.com",
    SafeAuto = "Claims@safeauto.com",
}

enum ClaimsPhone {
    Default = "800-468-3466",
    Encompass = "800-588-7400",
    Esurance = "800-378-7262",
    SafeAuto = "800-723-3288",
    Custom360 = "888-325-1190",

    NationalGeneralHome = "844-287-2236",
    FarmersUnionHome = "866-638-5677"
}
<div>
    <div class="flexColumn" *ngIf="!invalidDashboard; else invalidScreen">
        <div>Loading</div>
    </div>
    <ng-template #invalidScreen >
        <div class="container">
            <div class="jumbotron">
                <h2 class="display-2 text-center">Invalid Session ID</h2>
                <hr class="my-4">
                <div class="text-center">
                    <p class="lead">
                        The Session ID is invalid, unable to access the claim dashboard.
                    </p>
                </div>
            </div>
        </div>
    </ng-template>
</div>
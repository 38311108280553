import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
    selector: 'pageNotFound',
    templateUrl: '../views/pageNotFound.component.html'
})
export class PageNotFoundComponent extends BaseComponent {

    constructor(private router: Router, private location: Location) {
        super();
    }

    public ngOnInit(): void {

        var url = this.router.url;
        if (url.indexOf('?') > 0) {
            var legacyID = url = url.replace('/404?', '');
            legacyID = legacyID.replace('=', '');

            this.router.navigateByUrl('myClaims360/legacy/' + legacyID);
        }

    }

    public return(): void {
        this.location.back();
    }

}
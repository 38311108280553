import { Component } from "@angular/core";
import { BaseComponent } from "@Common/base/base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ClaimModel, SessionModel, DashboardModel } from "../../../ClaimsWeb.Areas.Models/models";
import { SessionService, ConstantsService } from "@Common/services";
import { MyClaimsApi } from "../services";



@Component({
    selector: 'legacy-component',
    templateUrl: '../views/legacy.component.html',
    styleUrls: ['../styles/mc360styling.css']
})


export class LegacyComponent extends BaseComponent
{
    constructor(private route: ActivatedRoute, private sessionService: SessionService, private myClaimsApi: MyClaimsApi, private router: Router,
                private constants: ConstantsService    )
    {
        super();
    }

    public session: SessionModel;
    public model: ClaimModel;
    public dashboard: DashboardModel;
    public invalidDashboard: boolean = false;

    public ngOnInit(): void
    {
        const legacyID = this.route.snapshot.paramMap.get("legacyId");
        this.myClaimsApi.getDashboardHeaderFromLegacyID(legacyID).then(dashboard =>
        {
            if (dashboard.DashboardSessionGuid == this.constants.EmptyGUIDFormat) {
                this.invalidDashboard = true;
            } else {
                const dashboardGUID: string = dashboard.DashboardSessionGuid
                this.router.navigateByUrl('myClaims360/bridge/' + dashboardGUID);
            }
        })



    }

}
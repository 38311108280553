import { Component, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { AddressModel, ClaimModel, BridgeModel, SessionModel } from '@ClaimsModels/index';
import { BaseComponent } from "@Common/base/base.component";
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ConstantsService, LayoutService, SessionService, SessionBridgeApi } from '@Common/services';
import { Location } from '@angular/common';

@Component({
    selector: 'bridge',
    templateUrl: '../views/bridge.component.html'
})

export class BridgeComponent extends BaseFormComponent {
    [x: string]: any;

    constructor(private location: Location, private constantsService: ConstantsService,
        private sessionService: SessionService,
        private bridgeApi: SessionBridgeApi,
        private route: ActivatedRoute,
        private spinner: Ng4LoadingSpinnerService,
        private myClaimsApi: MyClaimsApi,
        private dashboardService: DashboardService,
        private router: Router,        
        private layoutService: LayoutService)
    {
        super();
        this.vm = new BridgeModel();
    }

    public authentication: boolean = false;
    public sessionStarted: boolean = false;
    public hasInputError: boolean = false;
    public verifyStarted: boolean = false;
    public displayMessage: boolean = false;
    public displayEnterPINMessage: boolean = false;
    public loading: boolean = true;
    private session: SessionModel;
    public inputAttempts: number = 0;
    

    //public this.vm = new BridgeModel();
    public vm: BridgeModel = new BridgeModel();

    @ViewChild('requestPINForm') requestPINForm: NgForm;

    public ngOnInit(): void {
        this.vm.ClaimsAuthenticationServiceActive = true;
        this.vm.MaxFailureAttempts = false;
        this.vm.Verified = false;
        this.session = this.sessionService.getSession();

        let dashboardSessionGuid = this.route.snapshot.paramMap.get("sessionId");

        this.session.IsExpired = false;
        this.session.IsInitialized = false;
        this.authentication = false;
        this.sessionService.setSession(this.session);

        this.layoutService.setBrand(this.constantsService.MC360);

        this.spinner.show();
        this.vm.DashboardSessionGuid = dashboardSessionGuid;

        this.myClaimsApi.initiateDashboard(this.vm).then((response: BridgeModel) => {
            response.DeliveryMethod = null;

            this.session.ClaimId = response.ClaimID;
            this.sessionService.setSession(this.session);

            this.loading = false;
            this.vm = response;
            this.spinner.hide();

            if (!this.IsNullOrEmptyString(this.vm.Email)) {
                this.vm.DeliveryMethod = this.constantsService.Email;
            }
            else {
                this.vm.DeliveryMethod = this.constantsService.SMS;
            }


        });

    }

    public submit(form: NgForm) {

    }
    public goingBackWithLocation() {

    }
    

    submitPINRequest(form: NgForm): void {

        if (this.validate(form)) {
            if (!this.IsNullOrEmptyString(this.vm.DeliveryMethod)) {
                this.sessionStarted = false;
                this.vm.MaxFailureAttempts = false;
                this.vm.Action = this.constantsService.RequestPIN;
                this.spinner.show();
                this.myClaimsApi.authentication(this.vm).then((response: BridgeModel) => {
                    this.loading = false;
                    this.sessionStarted = true;
                    this.vm = response;
                    this.spinner.hide();
                });
            }
        }

    }

    submitVerifyPIN(form: NgForm): void {
        if (this.validate(form)) {
            if (!this.IsNullOrEmptyString(this.vm.PIN)) {
                this.spinner.show();
                this.vm.Action = this.constantsService.VerifyPIN;
                this.myClaimsApi.authentication(this.vm).then((response: BridgeModel) => {
                    this.loading = false;
                    this.verifyStarted = true;
                    this.vm = response;
                    this.displayMessage = false;
                    this.spinner.hide();
                    this.displayEnterPINMessage = false;
                    if (this.vm.Verified) {
                        this.dashboardService.setLossParticipantId(response.LossParticipantId.toString());
                        this.dashboardService.setDashboardSessionGuid(response.DashboardSessionGuid);
                        this.router.navigate(['myClaims360/dashboard/']);
                    }
                    else {
                        this.vm.PIN = "";
                        return;
                    }
                });
            }

        }
        else {
            this.displayEnterPINMessage = true;
        }
        return;
    }

    public resetPIN() {
        this.displayMessage = true;
        this.vm.PIN = null;
        this.vm.Message = null;
        this.vm.Action = this.constantsService.ResetPIN;
        this.spinner.show();
        this.myClaimsApi.authentication(this.vm).then((response: BridgeModel) => {
            this.loading = false;
            this.sessionStarted = true;
            this.vm = response;
            this.spinner.hide();
        });
    }

}